import {edit, get, create, store, update, destroy, disableUser, show} from "@/services/resources";
import {STAFF} from "@/services/apis";

export  const staff = {
    namespaced:true,
    state:{
        staff:undefined,
        staffDetails:undefined,
        createData:undefined,
        editData:undefined,

    },
    mutations: {
        SET_STAFF(state,payload){
            state.staff = [...payload]
        },
        STAFF_DETAILS(state,payload){
            state.staffDetails = payload
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        get({commit}){
            return new Promise((resolve,reject)=>{
                get(STAFF).then((response)=>{
                    commit('SET_STAFF',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit},module){
            return new Promise((resolve,reject)=>{
                create(module).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        edit({commit},cityId){
            return new Promise((resolve,reject)=>{
                edit(STAFF,cityId).then((response)=>{
                    commit('SET_EDIT_DATA',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        show({commit},staffId){
            return new Promise((resolve,reject)=>{
                show(STAFF,staffId).then((response)=>{
                    commit('STAFF_DETAILS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        store(_,data){
            return new Promise((resolve,reject)=>{
                store(STAFF,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        assignProducts(_,data){
            return new Promise((resolve,reject)=>{
                store("products-assign",data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        updateCredentials(_,data){
            return new Promise((resolve,reject)=>{
                store(`update-credentials/${data.staff_id}`,data).then((response)=>{
                    resolve(response)
                }).catch((error)=> {
                    reject(error)
                })
            })
        },
        update(_,data){
            return new Promise((resolve,reject)=>{
                update(STAFF,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy(_,itemId){
            return new Promise((resolve,reject)=>{
                destroy(STAFF,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        disableUser(_,userId){
            return new Promise((resolve,reject)=>{
                disableUser(userId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
