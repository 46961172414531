import guest from '@/router/middlewares/guest'
import Login from "@/views/auth/Login";
import Subscription from "@/views/auth/Subscription";
import Http403 from "@/views/Errors/403";
import auth from "@/router/middlewares/auth";
import Home from "@/views/Home";
import PasswordReset from "@/views/auth/PasswordReset";
import PasswordForgot from "@/views/auth/PasswordForgot";
import Users from "@/views/Accounts/Users";
import Customers from "@/views/Accounts/Customers";
import Company from "@/views/Accounts/Company";
import Games from "@/views/Games/Games";
import Profile from "@/views/Accounts/Profile";
import Categories from "@/views/Games/Categories";
import Types from "@/views/Games/Types";
import PlayGame from "@/views/Games/PlayGame";
import GamesPlayed from "@/views/Games/GamesPlayed";
import Configuration from "@/views/Config/Configuration";
import Tirages from "@/views/Games/Tirages";
import Gains from "@/views/Games/Gains";
import Licence from "@/views/Games/Licence";
import SellerDetails from "@/views/Accounts/SellerDetails.vue";




const routes = [
    {
        path:'/login',
        name:'login',
        component:Login,
        meta:{
            isAuth:true,
            middleware: [
                guest
            ]
        }
    },  {
        path:'/register',
        name:'register',
        component:Subscription,
        meta:{
            isAuth:true,
            middleware: [
                guest
            ]
        }
    },
    {
        path:'/403',
        name:'http-403',
        component:Http403,
        meta:{
            isAuth:true,
            middleware: [
                guest
            ]
        }
    },
    {
        path:'/invitation/:token',
        name:'invitation',
        component:PasswordReset,
        meta:{
            isAuth:true,
            middleware: [
                guest
            ]
        }
    },
    {
        path:'/password/reset/:token',
        name:'password.reset',
        component:PasswordReset,
        meta:{
            isAuth:true,
            middleware: [
                guest
            ]
        }
    },
    {
        path:'/password/forgot',
        name:'password.forgot',
        component:PasswordForgot,
        meta:{
            isAuth:true,
            middleware: [
                guest
            ]
        }
    },
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        name: 'home',
        component: Home,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },

    {
        path: '/users',
        name: 'users.index',
        component: Users,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/sellers/:id',
        name: 'sellers.show',
        component: SellerDetails,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/customers',
        name: 'customers.index',
        component: Customers,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/company',
        name: 'company.index',
        component: Company,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'manage-all'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/configurations',
        name: 'configs',
        component: Configuration,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/tirages',
        name: 'tirages',
        component: Tirages,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'manage-all'
        }
    },
    {
        path: '/games',
        name: 'games.index',
        component: Games,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'manage-all'
        }
    },
    {
        path: '/licences',
        name: 'licences.index',
        component: Licence,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'manage-all'
        }
    },
    {
        path: '/gains',
        name: 'gains.index',
        component: Gains,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/games/played',
        name: 'games.played',
        component: GamesPlayed,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/play-game',
        name: 'games.play',
        component: PlayGame,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'dashboard'
        }
    },
    {
        path: '/categories',
        name: 'categories.index',
        component: Categories,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'manage-all'
        }
    },
    {
        path: '/types',
        name: 'types.index',
        component: Types,
        meta:{
            middleware: [
                auth,
                //permission
            ],
            permission:'manage-all'
        }
    },
];

export  default routes;
