<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <h4 class="text-start fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Tableau de bord/</span> Gains
    </h4>
    <div v-if="allGains">
      <div class="row">
        <div class="col-md-4">
          <v-select solo
                    :items="games"
                    :item-value="(c)=>c.id"
                    :item-text="(c)=>c.name +' '+c.type.name"
                    label="Jeux"
                    @change="gameSelected"
                    v-model.trim="item.game_id"
                    required
          ></v-select>
        </div>
        <div class="col-md-4">
          <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="item.expire_at"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field solo
                            v-model="item.dates"
                            label="Période"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="item.dates"
                no-title
                @change="dateSelected()"
                range>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="dateMenu = false">
                Annuler
              </v-btn>
              <v-btn text
                     color="primary"
                     @click="$refs.menu.save(item.dates)">
                Valider
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-4">
          <v-text-field solo
                        v-model="search"
                        @keyup="searchTicket"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
          ></v-text-field>
        </div>
      </div>

      <v-card>
        <div class="row" v-if="allGains.length">
          <div class="col-md-4 col-sm-6" v-for="gain in allGains" :key="gain.id">
            <div v-if="gain.played" class="card bg-white p-2">
              <div class="d-flex justify-content-between">
                <div>
                  <v-chip small v-if="!gain.paid" color="error">Ticket non payé</v-chip>
                  <v-chip small v-else color="success">Ticket payé ({{ new Date(gain.paid_at).toLocaleDateString()}})</v-chip>
                </div>
                <div>
                  <v-btn small class="mr-2" icon @click="viewTicket(gain.id)">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                  <v-btn v-if="!gain.paid" @click="paidTicket(gain)" small class="mr-2" icon>
                    <v-icon>mdi-cash-multiple</v-icon>
                  </v-btn>
                  <v-btn small icon @click="printTicket(gain)">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </div>
              </div>
              <div>

              </div>
              <br>
              <div class="row">
                <div class="col-6">
                  {{ formatDate(gain.played.created_at) }}
                </div>
                <div class="col-6">
                  <strong>Validité : </strong> 90 Jours
                </div>
              </div>
              <div class="row" style="margin-top: -13px">
                <div class="col-6">
                  <strong>Tirage</strong> du
                  <strong>{{ new Date(gain.tirage.drawn_at).toLocaleDateString() }}</strong>
                  {{ gain.played.game.type.name }}
                </div>
                <div class="col-6">
                  <strong>Heure du tirage :</strong> {{ gain.played.game.normal_time.substr(0, 5) }}
                </div>
              </div>
              <div class="row" style="margin-top: -13px">
                <div class="col-6">
                  <strong>Ticket :</strong> {{ gain.played.num_ticket }}
                </div>
                <div class="col-6">
                  <strong>Série :</strong> {{ gain.played.num_serie }}
                </div>
              </div>
              <div class="text-center mt-2 mb-2"
                   style="border-bottom: black dashed;border-top: black dashed;font-weight: bold">
                                <span style="font-style: italic">
                                    {{ gain.played.game.name + ' ' + gain.played.game.type.name }}
                                </span>
              </div>

              <div style="display: none" :id="'ctn-'+gain.id">
                <!--- balls -->
                <h6 class="text-center font-italic">Vos boules</h6>
                <div v-for="ball in gain.borlettes" :key="ball.id">
                  <div class="row">
                    <strong class="col-4">BORLETTE</strong>
                    <span class="col-4 d-flex justify-content-center">{{ ball.number.number }}</span>
                    <span class="col-4 d-flex justify-content-end">{{ ball.gain }}</span>
                  </div>
                </div>
                <!--- lotto3 -->
                <div v-if="gain.lotto3s.length>0">
                  <div v-for="ball in gain.lotto3s" :key="ball.id">
                    <h6 class="text-center font-italic">Lotto 3 chiffres</h6>
                    <div class="row">
                      <strong class="col-4">LOTO3</strong>
                      <span class="col-4 d-flex justify-content-center">{{ ball.balls }}</span>
                      <span class="col-4 d-flex justify-content-end">{{ ball.gain }}</span>
                    </div>
                  </div>
                </div>
                <!--- lotto4 -->
                <div v-if="gain.lotto4s.length>0">
                  <h6 class="text-center font-italic">Lotto 4 chiffres</h6>
                  <div v-for="ball in gain.lotto4s" :key="ball.id">
                    <div class="row">
                      <strong class="col-4 ">LOTO4</strong>
                      <span class="col-4 d-flex justify-content-center">{{
                          ball.number1.number + '' + ball.number2.number
                        }}</span>
                      <span class="col-4 d-flex justify-content-end">{{ball.gain}}</span>
                    </div>
                  </div>
                </div>
                <!--- Marriage -->
                <div v-if="gain.marriages.length>0">
                  <h6 class="text-center font-italic">Marriages</h6>
                  <div v-for="(ball,index) in gain.marriages" :key="'mar-'+index">
                    <div class="row">
                      <strong class="col-4">MARIAGE </strong>
                      <span class="col-4 d-flex justify-content-center">{{
                          ball.number1.number + ' * ' + ball.number2.number
                        }}</span>
                      <span class="col-4 d-flex justify-content-end">{{ ball.gain }}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-between mt-4"
                     style="border-bottom: black dashed;border-top: black dashed;font-weight: bold">
                  <span>TOTAL</span>
                  <span>{{ gain.total_gains }}</span>
                </div>
                <div class="mt-4">
                  <h6 class="text-center" style="color: black">Gains {{ gain.played.game.name }}</h6>
                  <div>
                    <div class="d-flex justify-content-between">
                      <small>
                        <strong>BORL1</strong>
                      </small>
                      <small>
                        <strong>BORL2</strong>
                      </small>
                      <small>
                        <strong>BORL3</strong>
                      </small>
                      <small>
                        <strong>Lotto3</strong>
                      </small>
                      <small>
                        <strong>Lotto4</strong>
                      </small>
                      <small>
                        <strong>Marriage</strong>
                      </small>
                    </div>
                    <div class="d-flex justify-content-between">
                      <small>{{ gain.played.game.first_gain }}</small>
                      <small>{{ gain.played.game.second_gain }}</small>
                      <small>{{ gain.played.game.third_gain }}</small>
                      <small>{{ gain.played.game.lotto3_gain }}</small>
                      <small>{{ gain.played.game.lotto4_gain }}</small>
                      <small>{{ gain.played.game.marriage_gain }}</small>
                    </div>
                  </div>
                  <hr>
                  <div class="text-center font-italic">
                    <small style="color: red"> Revisez votre fiche avant le tirage, pas de reclamation après
                      tirage </small>
                  </div>
                  <div class="font-italic text-center mb-4">
                    <small>{{ gain.enterprise.slogan }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-warning text-center mt-4">Auncune donnée à afiicher !</div>
        <div>
<!--          <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :filename="gain?gain?.played?.num_ticket : 'ticket'"
              :pdf-quality="5"
              :manual-pagination="true"
              pdf-format="a8"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              pdf-content-heigth="100%"
              @hasStartedGeneration="hasStartedGeneration()"
              @hasGenerated="hasGenerated($event)"
              ref="html2Pdf">
            <section slot="pdf-content" v-if="gain">
              <div v-if="gain.played && gain.played.game" class="card bg-white p-2">
                <div>
                  <div class="text-center" style="margin-top: -5px">
                    <img alt="logo" class="enterprise-logo"
                         style="width: 80px; height: 80px; object-fit: contain;border-radius: 50%"
                         :src="gain.enterprise.logo_url"/>
                  </div>
                  <h5 class="text-center">{{ gain.enterprise? gain.enterprise.name :'&#45;&#45;'}}</h5>
                  <div class="text-center">
                    <small style="font-style:italic;margin-top: -10px"><strong>{{ gain.enterprise.slogan_2 }}</strong></small></div>
                </div>
                <br>
                <div class="row" style="margin-bottom: -35px !important;font-size: 8pt">
                  <div class="col-6">
                    <strong>{{ formatDate(gain.played.created_at) }}</strong>
                  </div>
                  <div class="col-6">
                    <strong>Validité : 90 Jours</strong>
                  </div>
                </div>
                <div class="row" style="margin-bottom: -35px !important;font-size: 8pt">
                  <div class="col-6">
                    <strong>Tirage du</strong>
                    <strong>{{ new Date(gain.tirage.drawn_at).toLocaleDateString() }} {{ gain.played.game.type.name }}</strong>

                  </div>
                  <div class="col-6">
                    <strong>Heure du tirage : {{ gain.played.game.normal_time.substr(0, 5) }}</strong>
                  </div>
                </div>

                <div class="row" style="font-size: 8pt">
                  <div class="col-6">
                    <strong>Ticket : {{ gain.played.num_ticket }}</strong>
                  </div>
                  <div class="col-6">
                    <strong>Série : {{ gain.played.num_serie }}</strong>
                  </div>
                </div>
                <div class="text-center mt-2 mb-2" style="border-bottom: black dashed;border-top: black dashed;font-weight: bold;margin-bottom: 15px">
                  <span style="font-style: italic">
                    {{ gain.played.game.name +' '+  gain.played.game.type.name }}
                  </span>
                </div>

                <div style="display: block;margin-top: 15px">
                  &lt;!&ndash;- balls &ndash;&gt;
                  <div v-for="(ball,index) in gain.balls" :key="'de-'+index">
                    <div class="row ticket-row">
                      <strong class="col-4">BOR</strong>
                      <span class="col-4 d-flex justify-content-center">{{ ball }}</span>
                      <span class="col-4 d-flex justify-content-end">{{ gain.gains_balls[index] }}</span>
                    </div>
                  </div>
                  &lt;!&ndash;- lotto3 &ndash;&gt;
                  <div v-if="gain.lotto3_balls.length>0">
                    <div v-for="(ball,index) in gain.played.lotto3_balls" :key="'ball-'+index">
                      <div class="row  ticket-row">
                        <strong class="col-4">LOT3</strong>
                        <span class="col-4 d-flex justify-content-center">{{ ball }}</span>
                        <span class="col-4 d-flex justify-content-end">{{ gain.gains_lotto3[index] }}</span>
                      </div>
                    </div>
                  </div>
                  &lt;!&ndash;- lotto4 &ndash;&gt;
                  <div v-if="gain.lotto4_balls.length>0">
                    <div v-for="(ball,index) in gain.played.lotto4_balls" :key="'ball-'+index">
                      <div class="row  ticket-row">
                        <strong class="col-4 ">LOT4</strong>
                        <span class="col-4 d-flex justify-content-center">{{
                            formatCombination(ball)[0] + '' + formatCombination(ball)[1]
                          }}</span>
                        <span class="col-4 d-flex justify-content-end">{{ gain.gains_lotto4[index] }}</span>
                      </div>
                    </div>
                  </div>
                  &lt;!&ndash;- Marriage &ndash;&gt;
                  <div v-if="gain.marriage_balls.length>0">
                    <div v-for="(ball,index) in gain.marriage_balls" :key="'mar-'+index">
                      <div class="row ticket-row">
                        <strong class="col-4">MAR </strong>
                        <span class="col-4 d-flex justify-content-center">{{
                            formatCombination(ball)[0] + ' * ' + formatCombination(ball)[1]
                          }}</span>
                        <span class="col-4 d-flex justify-content-end">{{ gain.gains_marriage[index] }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between"
                       style="border-bottom: black dashed;border-top: black dashed;font-weight: bold;margin-top: 5px">
                    <span>TOTAL</span>
                    <span>{{ gain.total_gains}}</span>
                  </div>
                  <div style="margin-top: 5px">
                    <h6 class="text-center" style="color: black;font-size: 9pt;margin-bottom: 20px">Gains {{ gain.played.game? gain.played.game.name :''}}</h6>
                    <div style="margin-top: -15px;margin-bottom: -10px">
                      <div class="d-flex justify-content-between gains">
                        <small>
                          <strong>BORL1</strong>
                        </small>
                        <small>
                          <strong>BORL2</strong>
                        </small>
                        <small>
                          <strong>BORL3</strong>
                        </small>
                        <small>
                          <strong>Lotto3</strong>
                        </small>
                        <small>
                          <strong>Lotto4</strong>
                        </small>
                        <small>
                          <strong>Marriage</strong>
                        </small>
                      </div>
                      <div class="d-flex justify-content-between">
                        <small>{{ gain.played.game.first_gain }}</small>
                        <small>{{ gain.played.game.second_gain }}</small>
                        <small>{{ gain.played.game.third_gain }}</small>
                        <small>{{ gain.played.game.lotto3_gain }}</small>
                        <small>{{ gain.played.game.lotto4_gain }}</small>
                        <small>{{ gain.played.game.marriage_gain }}</small>
                      </div>
                    </div>
                    <hr>
                    <div class="text-center font-italic" style="margin-top: -15px">
                      <small style="color: red"> Revisez votre fiche avant le tirage, pas de reclamation après
                        tirage </small>
                    </div>
                    <div class="font-italic text-center mb-4">
                      <small>{{ gain.enterprise.slogan }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </vue-html2pdf>-->
        </div>
      </v-card>
    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// @ is an alias to /src

import crudMixin from "@/mixins/crudMixin";
import globalMixins from "@/mixins/globalMixins";
import {mapState} from "vuex";
import VueHtml2pdf from 'vue-html2pdf'


export default {
  name: 'Gains',
  components: {VueHtml2pdf},
  mixins: [crudMixin(), globalMixins()],
  data() {
    return {
      search: "",
      dateMenu: false,
      dialog: false,
      formIsValid: false,
      submitting: false,
      currentItem : undefined,
      item: {dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)]},
      gain: undefined,
      allGains: undefined,
      src: "",

    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState('gains', ['gains']),
    ...mapState('games', ['games']),
  },
  methods: {
    init() {
      this.$store.dispatch('gains/get', this.item).then((response) => {
        this.allGains = response.data
        this.$store.dispatch('games/get', 'games');

      })
    },
    gameSelected(value) {
      this.item.game_id = value;
      this.init()
    },
    dateSelected() {
      this.init()
    },
    printTicket(gain) {
      this.gain = gain;
      if (this.$refs.html2Pdf) {
        this.$refs.html2Pdf.generatePdf()
      }
    },
    searchTicket() {
      this.allGains = this.gains.filter((gain) => {

        return gain.played &&
            gain.played.num_serie.toString().includes(this.search.toLowerCase()) ||
            gain.played.num_ticket.toString().includes(this.search.toLowerCase())
      })
    },
    paidTicket(item) {

      this.currentItem = item
      this.$swal({
        title: 'Validation', text: `Etes vous sûr(e) de marquer ce ticket comme payé?`,
        icon: 'warning', showCancelButton: true,
        confirmButtonText: 'Oui,payer', cancelButtonText: "Annuler"
      })
          .then((value => {
            this.validating = false;
            if (value.isConfirmed) {
              this.$store.dispatch(`gains/setGainAsPaid`,  item.id).then(() => {
                this.init();
                this.currentItem = undefined;

                this.$swal('Paiement', 'Ticket marqué comme payé avec succès', 'success')
                this.close();
              }).catch((error) => {
                this.validating = false;

                switch (error.response.status) {
                  case 500 :
                    this.$swal('Paiement', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                    break;
                }
              });
            }
          }))
    }

  }
}
</script>
<style>
.enterprise-logo {
  border-radius: 50% !important;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

 .ticket-row{
   margin-top: -25px;
     font-size: 9pt;
 }

.gains small strong{
  font-size: 6pt;
}
</style>
