<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <h4 class="text-start fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Tableau de bord/</span> Jeux Joués
    </h4>
    <div v-if="allPlayeds">
      <div v-if="!userHasPermission('manage-enterprise')" class="d-flex justify-content-end">
        <v-btn color="primary"
               class="ma-2 white--text"
               @click="()=>{$router.push({name:'games.play'})}">
          Jouer
        </v-btn>
      </div>
      <div class="row">
        <div class="col-md-4">
          <v-select solo
                    @change="gameSelected"
                    :items="games"
                    :item-value="(c)=>c.id"
                    :item-text="(c)=>c.name +' '+c.type.name"
                    label="Jeux"
                    v-model.trim="item.game_id"
                    required
          ></v-select>
        </div>
        <div class="col-md-4">
          <v-menu
              ref="menu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :return-value.sync="item.expire_at"
              transition="scale-transition"
              offset-y
              min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field solo
                            v-model="item.dates"
                            label="Période"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="item.dates"
                no-title
                @change="dateSelected()"
                range>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="dateMenu = false">
                Annuler
              </v-btn>
              <v-btn text
                     color="primary"
                     @click="$refs.menu.save(item.dates)">
                Valider
              </v-btn>
            </v-date-picker>
          </v-menu>

        </div>
        <div  class="col-md-4">
          <v-text-field solo
                        v-model="search"
                        @keyup="searchTicket"
                        append-icon="mdi-magnify"
                        label="Rechercher"
                        single-line
                        hide-details
          ></v-text-field>
        </div>
      </div>

      <v-card v-if="allPlayeds.length">
        <div class="row" >
          <div class="col-md-4" v-for="played in allPlayeds" :key="played.id" :id="'ticket-'+played.id">
            <div v-if="played.game" class="card bg-white p-3">
              <div class="d-flex justify-content-end" v-show="!imaging">
                <v-btn small icon @click="viewTicket(played.id)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>&nbsp;
                <v-btn small icon @click.prevent="printTicketImg(played)">
                  <v-icon>mdi-image-check-outline</v-icon>
                </v-btn>&nbsp;
                <v-btn small icon @click.prevent="printTicket(played)">
                  <v-icon>mdi-printer</v-icon>
                </v-btn>&nbsp;
              </div>

          <div :id="`full-ctn-${played.id}`">
            <div class="row">
              <div class="col-6">
                {{ formatDate(played.created_at) }}
              </div>
              <div class="col-6">
                <strong>Validité : </strong> 90 Jours
              </div>
            </div>
            <div class="row" style="margin-top: -13px">
              <div class="col-6">
                <strong>Ticket :</strong> {{ played.num_ticket }}
              </div>
              <div class="col-6">
                <strong>Série :</strong> {{ played.num_serie }}
              </div>
            </div>
            <div class="text-center mt-2 mb-2"
                 style="border-bottom: black dashed;border-top: black dashed;font-weight: bold">
              <span style="font-style: italic">
                {{ played.game.name+' '+ played.game.type.name }}
              </span>
            </div>
            <div style="display: none;background: white; padding: 15px" :id="'ctn-'+played.id">
           <div class="text-center">
             <img alt="logo" class="enterprise-logo"
                  style="width: 80px; height: 80px; object-fit: contain;border-radius: 50%"
                  :src="played.enterprise.logo_url"/>
           </div>

              <!--- balls -->
              <h6 class="text-center font-italic">Vos boules</h6>
              <div v-for="(ball,index) in played.borlettes" :key="'de-'+index">
                <div class="row">
                  <strong class="col-4">BOR</strong>
                  <span class="col-4 d-flex justify-content-center">{{ ball.number.number }}</span>
                  <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                </div>
              </div>
              <!--- lotto3 -->
              <div v-if="played.lotto3s.length>0">
                <h6 class="text-center font-italic">Lotto 3 chiffres</h6>
                <div v-for="(ball,index) in played.lotto3s" :key="'ball-'+index">
                  <div class="row">
                    <strong class="col-4">LOT3</strong>
                    <span class="col-4 d-flex justify-content-center">{{ ball.balls }}</span>
                    <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                  </div>
                </div>
              </div>
              <!--- lotto4 -->
              <div v-if="played.lotto4s.length>0">
                <h6 class="text-center font-italic">Lotto 4 chiffres</h6>
                <div v-for="(ball,index) in played.lotto4s" :key="'ball-'+index">
                  <div class="row">
                    <strong class="col-4 ">LOT4</strong>
                    <span class="col-4 d-flex justify-content-center">{{
                        ball.number1.number + '' + ball.number2.number
                      }}</span>
                    <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                  </div>
                </div>
              </div>
              <!--- Marriage -->
              <div v-if="played.marriages.length>0">
                <h6 class="text-center font-italic">Marriages</h6>
                <div v-for="(ball,index) in played.marriages" :key="'mar-'+index">
                  <div class="row">
                    <strong class="col-4">MAR </strong>
                    <span class="col-4 d-flex justify-content-center">{{
                          ball.number1.number + ' * ' + ball.number2.number
                        }}</span>
                    <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                  </div>
                </div>
              </div>

              <div class="mt-4"
                   style="border-bottom: black dashed;border-top: black dashed;font-weight: bold">
                <span>TOTAL</span>
                <span class="float-end">{{played.total_mises}}</span>
              </div>
              <div class="mt-4">
                <h6 class="text-center" style="color: black">Gains {{ played.game?played.game.name:'' }}</h6>
                <div>
                  <div class="row text-center">
                    <small  class="col-md-2">
                      <strong>B1</strong>
                    </small>
                    <small  class="col-md-2">
                      <strong>B2</strong>
                    </small>
                    <small  class="col-md-2">
                      <strong>B3</strong>
                    </small>
                    <small  class="col-md-2">
                      <strong>LT3</strong>
                    </small>
                    <small  class="col-md-2">
                      <strong>LT4</strong>
                    </small>
                    <small  class="col-md-2">
                      <strong>MAR</strong>
                    </small>
                  </div>
                  <div class="row text-center">
                    <small class="col-md-2">{{ played.game.first_gain }}</small>
                    <small class="col-md-2">{{ played.game.second_gain }}</small>
                    <small class="col-md-2">{{ played.game.third_gain }}</small>
                    <small class="col-md-2">{{ played.game.lotto3_gain }}</small>
                    <small class="col-md-2">{{ played.game.lotto4_gain }}</small>
                    <small class="col-md-2">{{ played.game.marriage_gain }}</small>
                  </div>
                </div>
                <hr>
                <div class="text-center font-italic">
                  <small style="color: red"> Revisez votre fiche avant le tirage, pas de reclamation après
                    tirage </small>
                </div>
                <div class="font-italic text-center mb-4">
                  <small>{{played.enterprise.slogan}}</small>
                </div>
              </div>
            </div>
          </div>

              <div class="d-flex justify-content-end mt-4">
                <v-btn v-if="userHasPermission('manage-enterprise')" small icon  @click="destroy(played,'playeds')">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
          </div>
        </div>
        </div>
        <div>
          <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              :filename="getFileName()"
              :pdf-quality="5"
              :manual-pagination="false"
              pdf-format="a8"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              ref="html2Pdf">

            <section class="print-container" slot="pdf-content" v-if="played">
              <div v-if="played && played.game" class="card bg-white p-2">
                <div>
                  <div class="row">
                    <div class="col-6">
                      {{ formatDate(played.created_at) }}
                    </div>
                    <div class="col-6">
                      <strong>Validité : </strong> 90 Jours
                    </div>
                  </div>
                  <div class="row" style="margin-top: -13px">
                    <div class="col-6">
                      <strong>Ticket :</strong> {{ played.num_ticket }}
                    </div>
                    <div class="col-6">
                      <strong>Série :</strong> {{ played.num_serie }}
                    </div>
                  </div>
                  <div class="text-center mt-2 mb-2"
                       style="border-bottom: black dashed;border-top: black dashed;font-weight: bold">
              <span style="font-style: italic">
                {{ played.game.name+' '+ played.game.type.name }}
              </span>
                  </div>
                  <div style="background: white; padding: 15px">
                    <div class="text-center">
                      <img alt="logo" class="enterprise-logo"
                           style="width: 80px; height: 80px; object-fit: contain;border-radius: 50%"
                           :src="played.enterprise.logo_url"/>
                    </div>
                    <!--- balls -->
                    <h6 class="text-center font-italic">Vos boules</h6>
                    <div v-for="(ball,index) in played.borlettes" :key="'de-'+index">
                      <div class="row">
                        <strong class="col-4 printer-fsize">BOR</strong>
                        <span class="col-4 d-flex justify-content-center">{{ ball.number.number }}</span>
                        <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                      </div>
                    </div>
                    <!--- lotto3 -->
                    <div v-if="played.lotto3s.length>0">
                      <h6 class="text-center font-italic">Lotto 3 chiffres</h6>
                      <div v-for="(ball,index) in played.lotto3s" :key="'ball-'+index">
                        <div class="row">
                          <strong class="col-4">LOT3</strong>
                          <span class="col-4 d-flex justify-content-center">{{ ball.balls }}</span>
                          <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                        </div>
                      </div>
                    </div>
                    <!--- lotto4 -->
                    <div v-if="played.lotto4s.length>0">
                      <h6 class="text-center font-italic">Lotto 4 chiffres</h6>
                      <div v-for="(ball,index) in played.lotto4s" :key="'ball-'+index">
                        <div class="row">
                          <strong class="col-4 ">LOT4</strong>
                          <span class="col-4 d-flex justify-content-center">{{
                              ball.number1.number + '' + ball.number2.number
                            }}</span>
                          <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                        </div>
                      </div>
                    </div>
                    <!--- Marriage -->
                    <div v-if="played.marriages.length>0">
                      <h6 class="text-center font-italic">Marriages</h6>
                      <div v-for="(ball,index) in played.marriages" :key="'mar-'+index">
                        <div class="row">
                          <strong class="col-4">MAR </strong>
                          <span class="col-4 d-flex justify-content-center">{{
                              ball.number1.number + ' * ' + ball.number2.number
                            }}</span>
                          <span class="col-4 d-flex justify-content-end">{{ ball.bet_amount }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="mt-4"
                         style="border-bottom: black dashed;border-top: black dashed;font-weight: bold">
                      <span>TOTAL</span>
                      <span class="float-end">{{played.total_mises}}</span>
                    </div>
                    <div class="mt-4">
                      <h6 class="text-center" style="color: black">Gains {{ played.game?played.game.name:'' }}</h6>
                      <div>
                        <div class="row">
                          <small  class="col-md-2">
                            <strong>B1</strong>
                          </small>
                          <small  class="col-md-2">
                            <strong>B2</strong>
                          </small>
                          <small  class="col-md-2">
                            <strong>B3</strong>
                          </small>
                          <small  class="col-md-2">
                            <strong>LT3</strong>
                          </small>
                          <small  class="col-md-2">
                            <strong>LT4</strong>
                          </small>
                          <small  class="col-md-2">
                            <strong>MAR</strong>
                          </small>
                        </div>
                        <div class="row">
                          <small class="col-md-2">{{ played.game.first_gain }}</small>
                          <small class="col-md-2">{{ played.game.second_gain }}</small>
                          <small class="col-md-2">{{ played.game.third_gain }}</small>
                          <small class="col-md-2">{{ played.game.lotto3_gain }}</small>
                          <small class="col-md-2">{{ played.game.lotto4_gain }}</small>
                          <small class="col-md-2">{{ played.game.marriage_gain }}</small>
                        </div>
                      </div>
                      <hr>
                      <div class="text-center font-italic">
                        <small style="color: red"> Revisez votre fiche avant le tirage, pas de reclamation après
                          tirage </small>
                      </div>
                      <div class="font-italic text-center mb-4">
                        <small>{{played.enterprise.slogan}}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </v-card>
      <div v-else class="text-center alert alert-warning mt-4">
        Aucune donnée trouvée !
      </div>
    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// @ is an alias to /src

import crudMixin from "@/mixins/crudMixin";
import globalMixins from "@/mixins/globalMixins";
import {mapState} from "vuex";
import domtoimage from 'dom-to-image';
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: 'GamesPlayed',
  components: {
    VueHtml2pdf,
  },
  mixins: [crudMixin(), globalMixins()],
  data() {
    return {
      dateMenu:"",
      search: "",
      dialog: false,
      formIsValid: false,
      imaging: true,
      submitting: false,
      validationErrors: undefined,
      update: false,
      played: undefined,
      item: {dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)]},
      actions_items: [
        {title: "Modifier", avatar: 'mdi-pencil', permission: ''},
        {title: "Supprimer", avatar: 'mdi-delete', permission: ''},
      ],
      allPlayeds: undefined

    }
  },
  mounted() {
    this.init()
    this.$store.dispatch('games/get', 'games');
    },
  computed: {
    ...mapState('playeds', ['playeds']),
    ...mapState('games', ['games']),

  },
  methods: {
    printTicketImg(played) {
      this.imaging = true
      this.played = played;
      domtoimage.toJpeg(document.getElementById(`ticket-${played.id}`), { quality: 0.95 })
          .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = `${played.num_serie}.jpeg`;
            link.href = dataUrl;
            link.click();
          this.played = undefined
            this.imaging = false

          });
    },
    printTicket(played) {
      this.played = played;
      if (this.$refs.html2Pdf)
        this.$refs.html2Pdf.generatePdf()
    },
    init() {
      this.$store.dispatch('playeds/get', this.item).then((response) => {
        this.allPlayeds = response.data
      })
    },
    searchTicket() {
      this.allPlayeds = this.playeds.filter((played) => {
        return played &&
            played.num_serie.toString().includes(this.search.toLowerCase()) ||
            played.num_ticket.toString().includes(this.search.toLowerCase())
      })
    },
    gameSelected(value) {
      this.item.game_id = value;
      this.init()
    },
    dateSelected() {
      this.init()
    },
    calculateGameAmount(played) {
      return played.mises_balls.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) +
          played.mises_lotto3.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) +
          played.mises_lotto4.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0) +
          played.mises_marriage.reduce(function (a, b) {
            return parseInt(a) + parseInt(b);
          }, 0);

    },

    getFileName() {
      return this.played?this.played.num_ticket : 'ticket';
    },
    calculateBallsAmount(balls, misesBalls) {
      let total = 0;

      balls.forEach((ball, index) => {
        if (!isNaN(misesBalls[index]))
          total += parseInt(misesBalls[index])
      })

      return total;
    }
  }
}
</script>
<style>
.print-container div{
  font-size: 6pt !important;
}
.print-container h6{
  font-size: 6pt !important;
}
</style>