import {store, update, destroy, get} from "@/services/resources";
import {PRODUCTS} from "@/services/apis";

export  const productsMarriages = {
    namespaced:true,
    state:{
        products:undefined,
    },
    mutations: {
        SET_PRODUCTS(state,payload){
            state.products = [...payload]
        },
    },

    actions: {
        get({commit}){
            return new Promise((resolve,reject)=>{
                get(PRODUCTS).then((response)=>{
                    commit('SET_PRODUCTS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        store(_,data){
            return new Promise((resolve,reject)=>{
                store("products-marriages",data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update(_,data){
            return new Promise((resolve,reject)=>{
                update(PRODUCTS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy(_,itemId){
            return new Promise((resolve,reject)=>{
                destroy(PRODUCTS,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
