<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <div class="d-flex justify-content-end">

      <v-btn @click="reset" outlined icon color="transparent">
        <v-icon color="primary">mdi-refresh</v-icon>
      </v-btn>
      <v-btn @click="selectOption('combination')" outlined icon color="transparent">
        <v-icon color="primary">mdi-arrange-bring-to-front</v-icon>
      </v-btn>
      <v-btn @click.prevent="save" outlined icon color="transparent">
        <v-icon color="primary">mdi-check</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs"
                  v-on="on" outlined icon color="transparent">
            <v-icon color="primary">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="product in products" :key="product.id">
            <v-list-item-title>
              <a href="#" @click.prevent="selectOption('product',product)">{{product.name}}</a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <a href="#" @click.prevent="selectOption('grappe')">Grappe</a>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <a href="#" @click.prevent="pointeModal = true">Pointe</a>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="d-flex" style="overflow-x: scroll">
      <v-btn  small rounded color="primary" v-for="(game) in games" :key="game.id"
             :outlined="item.game_id!==game.id" class="m-2" @click="selectGame(game.id)">
        {{ game.name + ' ' + game.type.name }}
      </v-btn>
    </div>
    <div>
       <span v-if="hasError(`game_id`)" class="text-danger" role="alert">
         <strong>{{validationErrors[`game_id`][0]}}</strong>
       </span>
    </div>
 <div>
   <span v-if="hasError(`balls`)" class="text-danger" role="alert">
     <strong>{{validationErrors[`balls`][0]}}</strong>
   </span>
 </div>

    <div style="font-size: 2em!important;color: #ED733E" class="d-flex justify-content-center" id="clock"></div>
    <div v-if="games">
      <v-progress-linear
          v-if="submitting"
          indeterminate
          color="primary"
      ></v-progress-linear>
      <div class="bg-white mt-2 p-2">
      <div class="d-flex justify-content-end">
        <strong>TOTAL : {{totalAmount}}</strong>
      </div>
        <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil">
          <v-tab>
            Boules ({{item.balls?.length}} : {{item.balls_amounts.length ? item.balls_amounts.reduce((total, current) => parseInt(total) + parseInt(current)) : 0}})
          </v-tab>
          <v-tab>
            Marriages ({{item.marriages?.length}} : {{item.marriages_amounts.length ? item.marriages_amounts.reduce((total, current) => parseInt(total) + parseInt(current)) : 0}})
          </v-tab>
          <v-tab>
            Lotto3 ({{item.lotto3s?.length}} : {{item.lotto3s_amounts.length ? item.lotto3s_amounts.reduce((total, current) => parseInt(total) + parseInt(current)) : 0}})
          </v-tab>
          <v-tab>
            Lotto4 ({{item.lotto4s?.length}} : {{item.lotto4s_amounts.length ? item.lotto4s_amounts.reduce((total, current) => parseInt(total) + parseInt(current)) : 0}})
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
              v-for="index in items"
              :key="index">
            <div class="p-2">
              <!-- Boules -->
              <section v-if="tab === 0">
                <div v-for="(index,i) in maximumBorlettes" :key="i">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <input :class="[hasError(`balls.${i}`)?'is-invalid':'']"
                             maxlength="2"
                             type="tel"
                             v-model="item.balls[i]"
                             class="form-control"
                             placeholder="Bor" />
                      <span v-if="hasError(`balls.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`balls.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-5">
                      <input
                          :class="[hasError(`balls_amounts.${i}`)?'is-invalid':'']"
                          class="form-control" type="number" v-model="item.balls_amounts[i]" placeholder="Montant" />
                      <span v-if="hasError(`balls_amounts.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`balls_amounts.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-1">
                      <button @click.prevent="revert(i)">
                        <v-icon color="primary">mdi-content-copy</v-icon>
                      </button>
                    </div>
                    <div class="col-1">
                      <button @click.prevent="remove('ball',i)">
                        <v-icon color="error">mdi-close</v-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Boules -->

              <!-- Marriages -->
              <section v-if="tab === 1">
                <div v-for="(index,i) in maximumBorlettes" :key="i">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <input  :class="[hasError(`marriages.${i}`)?'is-invalid':'']" maxlength="4" type="tel" v-model="item.marriages[i]" class="form-control" placeholder="Mar" />
                      <span v-if="hasError(`marriages.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`marriages.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-6">
                      <input :class="[hasError(`marriages_amounts.${i}`)?'is-invalid':'']" class="form-control" type="number" v-model="item.marriages_amounts[i]" placeholder="Montant" />
                      <span v-if="hasError(`marriages_amounts.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`marriages_amounts.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-1" style="margin-left: -20px">
                      <button>
                        <v-icon color="error">mdi-close</v-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Marriages -->

              <!-- Lotto3 -->
              <section v-if="tab === 2">
                <div v-for="(index,i) in maximumBorlettes" :key="i">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <input :class="[hasError(`lotto3s.${i}`)?'is-invalid':'']" maxlength="3" type="tel" v-model="item.lotto3s[i]" class="form-control" placeholder="Lotto3" />
                      <span v-if="hasError(`lotto3s_amounts.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`lotto3s.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-6">
                      <input :class="[hasError(`lotto3s_amounts.${i}`)?'is-invalid':'']" class="form-control" type="number"  v-model="item.lotto3s_amounts[i]" placeholder="Montant" />
                      <span v-if="hasError(`lotto3s_amounts.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`lotto3s_amounts.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-1">
                      <button @click.prevent="remove('lotto3',i)">
                        <v-icon color="error">mdi-close</v-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Lotto3 -->

              <!-- Lotto4 -->
              <section v-if="tab === 3">
                <div v-for="(index,i) in maximumBorlettes" :key="i">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <input :class="[hasError(`lotto4s.${i}`)?'is-invalid':'']" maxlength="4" type="tel" v-model="item.lotto4s[i]" class="form-control" placeholder="Lotto4" />
                      <span v-if="hasError(`lotto4s.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`lotto4s.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-6">
                      <input :class="[hasError(`lotto4s_amounts.${i}`)?'is-invalid':'']" class="form-control" type="number"  v-model="item.lotto4s_amounts[i]" placeholder="Montant" />
                      <span v-if="hasError(`lotto4s_amounts.${i}`)" class="invalid-feedback" role="alert">
                        <strong>{{validationErrors[`lotto4s_amounts.${i}`][0]}}</strong>
                      </span>
                    </div>
                    <div class="col-1">
                      <button @click.prevent="remove('lotto4',i)">
                        <v-icon color="error">mdi-close</v-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
              <!-- Lotto4 -->

            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <!-- Dialog start -->
    <v-dialog v-model="dialog"
              persistent
              max-width="1000px">

      <v-card>
        <v-btn class="float-end m-1" color="transparent" @click="close()">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <span class="text-h5">Information du client</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formIsValid">

                <div class="d-flex justify-content-between">
                  <v-checkbox
                      v-model="clientExist"
                      label="Le client existe"
                  ></v-checkbox>

                  <v-col md="4" v-if="clientExist">
                    <v-autocomplete
                        @change="selectClient"
                        v-model="item.client_id"
                        :items="clients"
                        :item-text="(client)=>client.lastname+' '+client.firstname"
                        :item-value="(client)=>client.id"
                        dense
                        label="Selectionner le client"
                        solo
                    ></v-autocomplete>
                  </v-col>
                </div>
                <v-row>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <v-text-field solo :rules="[rules.required]"
                                  label="Nom du client"
                                  v-model="item.client.firstname"
                                  required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <v-text-field solo :rules="[rules.required]"
                                  label="Prénom du client"
                                  v-model="item.client.lastname"
                                  required
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="4"
                      md="4">
                    <VueTelInput
                        class="v-text-field tel-input"
                        :defaultCountry="229"
                        invalidMsg="Numéro de téléphone invalid"
                        @validate="phoneChange"
                        v-model="item.client.telephone"
                        :autoDefaultCountry="false"></VueTelInput>
                  </v-col>
                </v-row>
              </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="dark"
              @click="item.client = {}; dialog = false">
            Annuler
          </v-btn>
          <v-btn
              color="primary"
              @click="dialog = false">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pointeModal"
              persistent
              max-width="1000px">

      <v-card>
        <v-btn class="float-end m-1" color="transparent" @click="close()">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <span class="text-h5">Pointe</span>
        </v-card-title>
        <v-card-text>
          <div class="row g-3">
            <div>
              <input type="tel" class="form-control" v-model="pointeNumber" maxlength="1" placeholder="Numéro" />
            </div>
            <div>
              <input type="number" class="form-control" v-model="amount"  placeholder="Montant" />
            </div>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="dark"
              @click="pointeModal = false">
            Annuler
          </v-btn>
          <v-btn
              color="primary"
              @click="generatePointe">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="amountModal"
              persistent
              max-width="400px">
      <v-card>
        <v-btn class="float-end m-1" color="transparent" @click="close()">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <span class="text-h5">Montant</span>
        </v-card-title>
        <v-card-text>
          <div>
            <input type="number" class="form-control" v-model="amount" placeholder="Montant" />
          </div>
          <div>
            <v-radio-group v-model="combineType">
             <div class="row">
               <div class="col-6">
                 <v-radio label="Classique"  value="classic"/>
               </div>
               <div class="col-6">
                 <v-radio label="Full" value="full"/>
               </div>
             </div>
            </v-radio-group>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="dark"
              @click="amountModal = false">
            Annuler
          </v-btn>
          <v-btn
              color="primary"
              @click="validateOption">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog end -->
  </div>
</template>

<script>
// @ is an alias to /src
import crudMixin from "@/mixins/crudMixin";
import globalMixins from "@/mixins/globalMixins";
import {mapState} from "vuex";
import {VueTelInput} from 'vue-tel-input'

export default {
  name: 'PlayGame',
  components: {
    VueTelInput
  },
  mixins: [crudMixin(), globalMixins()],
  data() {
    return {
      maximumBorlettes : 50,
      clientExist: false,
      phoneIsValid: false,
      panel: [0],
      lotto4Ball: {first: '', second: ''},
      marriageBall: {first: '', second: ''},
      dialog: false,
      formIsValid: false,
      submitting: false,
      pointeModal: false,
      amountModal: false,
      amount : '',
      selectedOption : '',
      pointeNumber: '',
      ballsAmounts: 0,
      lotto3sAmount: 0,
      lotto4sAmount: 0,
      marriagesAmount: 0,
      time: "",
      combineType: "classic",
      configs: '',
      totalAmount: 0,
      item: {
        client: {},
        balls: [],
        balls_amounts: [],
        lotto3s: [],
        lotto3s_amounts: [],
        lotto4s: [],
        lotto4s_amounts: [],
        marriages: [],
        marriages_amounts: [],
        game_id: '',
      },
      validationErrors: undefined,
      update: false,
      hour: "10:30",
      tab: 0,
      items: [
        'Boules', 'Marriage', 'Lotto3', 'Lotto4',
      ],
      product:undefined,
      productIds:undefined,
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
  mounted() {
    this.init('categories')
    this.init('types')
    this.init('games')
    this.init('products')

    this.$store.dispatch('gameConfigs/get')
    this.$store.dispatch('balls/resetBalls')

  },
  computed: {
    ...mapState('products', ['products']),
    ...mapState('categories', ['categories']),
    ...mapState('types', ['types']),
    ...mapState('games', ['games']),
    ...mapState('gameConfigs', ['gameConfigs']),
    ...mapState('clients', ['clients']),

  },
  methods: {
    calculateSum(array) {
      return array.reduce(function (a, b) {
        return parseInt(a) + parseInt(b);
      }, 0)
    },
    selectClient() {
      this.item.client = this.clients.find((c) => c.id === this.item.client_id)
    },
    save() {
      this.submitting = true;
      this.$store.dispatch('playeds/store', this.item).then(() => {
        this.submitting = false;
        Object.assign(this.$data, this.$options.data())
        this.$swal('Jeux', 'Jeu enrégistré avec succès', 'success')
        this.$store.dispatch("balls/resetBalls")
      }).catch(error => {
        switch (error.response.status) {
          case 500 :
            this.$swal('Jeux', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
            break;
          case 422:
            this.validationErrors = error.response.data.errors
              this.toast('error',"Oups! Certains champs comportent des données invalides")
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });

            break;
        }
      }).finally(() => this.submitting = false)
    },
    remove(element, index) {
      switch (element) {
        case 'balls' :
          this.item.balls = this.item.balls.splice(index, 1)
          this.item.balls_amounts = this.item.balls_amounts.splice(index, 1)
          break;
        case 'lotto3' :
          this.item.lotto3s = this.item.lotto3s.splice(index, 1)
          this.item.lotto3s_amounts = this.item.lotto3s_amounts.splice(index, 1)
          break;
        case 'lotto4' :
          this.item.lotto4s = this.item.lotto4s.splice(index, 1)
          this.item.lotto4s_amounts = this.item.lotto4s_amounts.splice(index, 1)
          break;
        case 'marriage' :
          this.item.marriages = this.item.marriages.splice(index, 1)
          this.item.marriages_amounts = this.item.marriages_amounts.splice(index, 1)
          break;
      }
    },
    revert(index) {
      let ball = this.item.balls[index];
      let splitBall = ball.split('');
      let reverseBall = splitBall.reverse();
      let reversedBall = reverseBall.join('')
      if (!this.item.balls.includes(reversedBall)){
        this.item.balls.push(reversedBall)
        this.item.balls_amounts.push(this.item.balls_amounts[index])
      }
    },
    chooseProduct(product) {
      this.item.balls = [...this.item.balls,...product.borlettes.map((item) => item.number)]
      this.product = product;

      product.borlettes.forEach(() => {
        this.item.balls_amounts = [...this.item.balls_amounts,...[this.amount]]
      })

    },
    generateGrappe(){
      this.item.lotto3s = [...this.item.lotto3s,...['000']]
      this.item.lotto3s_amounts = [...this.item.lotto3s_amounts,...[this.amount]]
      for (let i = 0; i<= 9; i++){
        let grappe = i.toString().repeat(3)
        this.item.lotto3s = [...this.item.lotto3s,...[grappe]]
        this.item.lotto3s_amounts = [...this.item.lotto3s_amounts,...[this.amount]]
      }
    },
    generatePointe(){
      this.item.balls = [`0${this.pointeNumber}`.toString()]
      for (let i = 0; i <= 9; i++){
        let pointe = i * 10 + parseInt(this.pointeNumber)
        this.item.balls = [...this.item.balls,...[pointe]]
        this.item.balls_amounts[i] = this.amount
      }
      this.pointeModal = false;
    },
    generate() {
      switch (this.tab) {
        case 1 :
          this.generateMarriage()
          break;
        case 2 :
          break;
        case 3 :
          this.generateLotto4()
          break;
      }
    },
    reset() {
      switch (this.tab) {
        case 0 :
          this.item.balls = [];
          this.item.balls_amounts = [];
          break;
        case 1 :
          this.item.marriages = [];
          this.item.marriages_amounts = [];
          break;
        case 2 :
          this.item.lotto3s = [];
          this.item.lotto3s_amounts = [];
          break;
        case 3 :
          this.item.lotto4s = [];
          this.item.lotto4s_amounts = [];
          break;
      }
    },
    generateMarriage() {
      this.item.marriages = this.generateBallsCombinations(this.item.balls, this.item.balls,true);
      this.item.marriages.forEach((item) => {
        if (item){
          this.item.marriages_amounts = [...this.item.marriages_amounts,...[this.amount]]
        }
      })
    },
    generateLotto4() {
      for (let i = 0; i < this.item.balls.length; i++) {
        for (let j = 0; j < this.item.balls.length; j++) {
            let borlette = `${this.item.balls[i]}${this.item.balls[j]}`
          if (this.combineType === 'full'){
            this.item.lotto4s = [...this.item.lotto4s,...[borlette]]
            this.item.lotto4s_amounts = [...this.item.lotto4s_amounts,...[this.amount]]
          }else{
            if (this.item.balls[i] !== this.item.balls[j]){
              this.item.lotto4s = [...this.item.lotto4s,...[borlette]]
              this.item.lotto4s_amounts = [...this.item.lotto4s_amounts,...[this.amount]]
            }
          }
        }
      }
    },
    generateBallsCombinations(arr1 = [], arr2 = [],isUnique = false) {
      const res = [];
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
         let combination = arr1[i] + arr2[j];
         let reverseCombination =  arr2[j] + arr1[i]
          if (isUnique){
            if (!res.includes(reverseCombination)){
              if (this.combineType === 'full'){
                res.push(combination)
              }else{
                if (combination !== reverseCombination){
                  res.push(combination)
                }
              }
              //res.push(combination)
            }
          }else{
            if (this.combineType === 'full'){
              res.push(combination)
            }else{
              if (combination !== reverseCombination){
                res.push(combination)
              }
            }
            //res.push(combination)
          }
        }
      }
      return res;
    },
    selectOption(option,product = null){
     // if ((this.tab === 1 || this.tab === 3) && this.item.balls.length > 1){
        this.option = option;
        this.product = product;
        this.amountModal = true
     // }
    },
    validateOption(){
      switch (this.option){
        case 'product':
          this.chooseProduct(this.product)
          break;
        case 'grappe':
          this.generateGrappe()
          break;
        case 'combination':
          this.generate()
          break;
        default:
          this.toast('error','Produit inconnu')
          break;
      }
      this.amountModal = false
    }
  },
  watch : {
    item : {
      handler(newValue) {
        parseInt(newValue.balls_amounts.map(amount =>  this.totalAmount += parseInt(amount)));
        parseInt(newValue.lotto4s_amounts.map(amount =>  this.totalAmount += parseInt(amount)));
        parseInt(newValue.lotto3s_amounts.map(amount =>  this.totalAmount += parseInt(amount)));
        parseInt(newValue.marriages_amounts.map(amount =>  this.totalAmount += parseInt(amount)));
      },
      deep: true
    }
  }
}
</script>
<style>
.position-relative {
  position: relative;
}

.borlettes {
  margin-right: 2px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 0 !important;
}

</style>