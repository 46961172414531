<template>
  <div>
    <div class="container-xxl flex-grow-1 container-p-y" v-if="data || submitting ">

      <div class="d-flex justify-content-center mb-4">
        <a href="#" class="btn card col-md-4 mb-2" @click="showStats = !showStats">
          <v-img :src="`assets/img/icons/analytics.png`" alt="" width="50" style="margin: auto" />
          Consulter les Statistiques
        </a>
      </div>

      <div class="row" v-if="showStats">
        <div class="row d-flex justify-content-center" v-if="games">
          <div class="col-md-4">
            <v-select solo
                      @change="gameSelected"
                      :items="[...[{id : 'all',name : 'Tout les jeux',type : {name:''}}],...games]"
                      :item-value="(c)=>c.id"
                      :item-text="(c)=>c.name +' '+c.type.name"
                      label="Jeux"
                      v-model.trim="item.game_id"
                      required
            ></v-select>
          </div>
          <div class="col-md-4">
            <v-menu
                ref="menu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field solo
                              v-model="item.dates"
                              label="Période"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="item.dates"
                  no-title
                  @change="dateSelected()"
                  range>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="dateMenu = false">
                  Annuler
                </v-btn>
                <v-btn text
                       color="primary"
                       @click="$refs.menu.save(item.dates)">
                  Valider
                </v-btn>
              </v-date-picker>
            </v-menu>

          </div>
        </div>

        <div class=" col-md-12 col-lg-12 col-xl-12 order-0 mb-4">
          <div class="card-title mb-0">
            <div class="row bg-white p-2 mb-4" style="border-radius: 5px">
              <h5>Statistiques globales</h5>
              <div class="col-md-4">
                Nb Total de vente : {{data.total.total_sells}}
              </div>
              <div class="col-md-4">
                Chiffre d'affaire : {{data.total.total_sells_amount}}
              </div>
              <div class="col-md-4">
                Profit / Perte : {{data.total.solde}}
              </div>
            </div>
            <div class="row d-flex justify-content-between">
              <div v-for="(item,index) in data.sellers" :key="'dash-'+index" class="col-md-3 card m-2">
                <h5 class="d-flex justify-content-between mt-4" style="position: relative">{{item.seller}}
                  <v-switch style="position: absolute;top: -90%;right: -15%"  :disabled="userHasPermission('manage-all')" v-if="item?.seller_id" @click="changeUserStatus(item.seller_id)" color="primary"
                             :input-value="item.active ">
                  </v-switch>
                  <v-btn small icon @click="printTicket(item)">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </h5>
                <hr style="margin-top: -10px">
                <div class="d-flex justify-content-between">
                  <span>Nbre tickets</span>
                  <span  class="fw-bold">{{item.total_sells}}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span>Chiffre d'affaires </span>
                  <span  class="fw-bold">{{formatAmount(item.total_sells_amount)}}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span>Paiement vendeur</span>
                  <span  class="fw-bold">{{formatAmount(item.total_commission)}} </span>
                </div>
                <div class="d-flex justify-content-between">
                  <span>Paiement clients </span>
                  <span  class="fw-bold">{{formatAmount(item.total_gains)}}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span>Profit/Perte</span>
                  <span class="fw-bold">{{formatAmount(item.total_sells_amount - (item.total_gains + item.total_commission))}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="seller">
          <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="true"
              :paginate-elements-by-height="1400"
              :filename="seller.seller+' '+seller.period"
              :pdf-quality="5"
              :manual-pagination="false"
              pdf-format="a6"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              ref="html2Pdf">

            <section slot="pdf-content" v-if="seller">
              <div class="container">
                <h5 class="text-center">Rapport du {{seller.period}}</h5>
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td>Filtre tirage : </td>
                    <td>{{seller.game}}</td>
                  </tr>
                  <tr>
                    <td>Vendeur : </td>
                    <td>{{seller.seller}}</td>
                  </tr>
                  <tr>
                    <td>Date du rapport : </td>
                    <td>{{seller.date}}</td>
                  </tr>
                  <tr>
                    <td>Fiches vendues : </td>
                    <td>{{seller.total_sells}}</td>
                  </tr>
                  <tr>
                    <td>Fiches gagnantes: </td>
                    <td>{{seller.total_gains_count}}</td>
                  </tr>
                  <tr>
                    <td>Chiffre d'affaires : </td>
                    <td>{{seller.total_sells_amount?formatAmount(seller.total_sells_amount):'0'}}</td>
                  </tr>
                  <tr>
                    <td>Pourcentage : </td>
                    <td>{{seller.commission}} %</td>
                  </tr>
                  <tr>
                    <td>Commission : </td>
                    <td>{{seller.total_commission?formatAmount(seller.total_commission):'0'}}</td>
                  </tr>
                  <tr>
                    <td>Paiement : </td>
                    <td>{{seller.total_gains?formatAmount(seller.total_gains):'0'}}</td>
                  </tr>
                  <tr>
                    <td>Profit / Perte : </td>
                    <td>{{seller.solde? formatAmount(seller.total_sells_amount - (seller.total_commission + seller.total_gains)):'0'}}</td>
                  </tr>

                  </tbody>
                </table>
                <div class="d-flex justify-content-end">
                  <i>imprimé le {{seller.date}}</i>
                </div>
              </div>
            </section>
          </vue-html2pdf>

        </div>
      </div>
      <dashboard-menu/>
    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
    import {mapState} from "vuex";
    import globalMixins from "@/mixins/globalMixins";
    import VueHtml2pdf from 'vue-html2pdf'
    import DashboardMenu from "@/components/DashboardMenu.vue";

    export default {
        name: "AdminDashboard",
      mixins:[globalMixins()],
      components: {DashboardMenu, VueHtml2pdf},
      data(){
          return {
            showStats : false,
            dateMenu:"",
            submitting : false,
            seller:undefined,
            item: {game_id : 'all',dates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)]},
          }
      },
        computed:{
           ...mapState('dashboard',['data']),
          ...mapState('games', ['games']),
        },
        mounted() {
          this.init();
          this.$store.dispatch('games/get','games').then(()=>{})
        },
        methods:{
          init(){
            this.$store.dispatch('dashboard/get',this.item).then(()=>{
              this.submitting = false;

            })
          },
          printTicket(seller) {
            this.seller = seller;
            if (this.$refs.html2Pdf)
              this.$refs.html2Pdf.generatePdf()
          },
          gameSelected(value) {
            this.item.game_id = value;
            this.submitting = true;
            this.init()
          },
          dateSelected() {
            this.submitting = true;
            this.init()
          },
        }
    }
</script>

<style scoped>

</style>