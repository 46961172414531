import { get, create, store, update, destroy, show} from "@/services/resources";
import {PRODUCTS} from "@/services/apis";

export  const products = {
    namespaced:true,
    state:{
        products:undefined,
        createData:undefined,
    },
    mutations: {
        SET_PRODUCTS(state,payload){
            state.products = [...payload]
        },

        SET_CREATE_DATA(state,payload){
            state.createData = {...payload}
        },
        SET_EDIT_DATA(state,payload){
            state.editData = {...payload}
        },

    },
    actions: {
        get({commit},module){
            return new Promise((resolve,reject)=>{
                get(module).then((response)=>{
                    commit('SET_PRODUCTS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        create({commit}){
            return new Promise((resolve,reject)=>{
                create(PRODUCTS).then((response)=>{
                    commit('SET_CREATE_DATA',response.data)
                    resolve(response.data)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        changeStatus(_,productId){
            return new Promise((resolve,reject)=>{
                show('products-toggle',productId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

        store(_,data){
            return new Promise((resolve,reject)=>{
                store(PRODUCTS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        toggleStaffProduct(_,data){
            return new Promise((resolve,reject)=>{
                store('products-assign',data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        update(_,data){
            return new Promise((resolve,reject)=>{
                update(PRODUCTS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        destroy(_,itemId){
            return new Promise((resolve,reject)=>{
                destroy(PRODUCTS,itemId).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
