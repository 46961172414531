/*eslint-disable*/
//import moment from "moment";
import moment from "moment-timezone"
moment.locale("fr");
export default function () {
    return {
        data() {
            return {
                globalRules: {
                    string: v => !!v && typeof v === 'string' || "Valeur invalid",
                    number: v => !isNaN(v) || "Valeur invalid",
                    positive: v => !isNaN(v) && v > 0 || "La valeur de ce champ doit être supérieur à zéro",
                    required: v => !!v || "Ce champ est requis",
                }
            }
        },
        methods: {
            hasError(input) {
                return  this.validationErrors?.[input]
            },
            changeUserStatus(item){
                let text = item.disabled_at == null?
                    `Êtes vous sûr de vouloir désactivé ce compte`
                    :`Êtes vous sûr de vouloir activé ce compte`;
                let title = item.disabled_at == null? "Désactivation":"Activation";
                this.$swal({title:title,text:text,
                    icon:'warning',showCancelButton: true,
                    confirmButtonText: 'Continuer',cancelButtonText:"Annuler"})
                    .then((value => {
                        if (value.isConfirmed){
                            this.$store.dispatch('staff/disableUser',item.id || item).then((response)=>{
                                this.$swal(title,'Opération effectué avec succès','success')
                                this.init()
                            }).catch((error)=>{
                                if (error.response){
                                    switch (error.response.status) {
                                        case 500 :
                                            this.$swal(title,'Oups! une erreur est survenue, veuillez réssayer','error')
                                                .then(() => {location.reload() })
                                            break;
                                            case 406 :
                                            this.$swal("Erreur",error.response.data.message,'error')
                                                .then(() => {location.reload() })
                                                break;
                                    }
                                }

                            });
                        }
                    }))
            },
            viewTicket(id){
                let element = $(`#ctn-${id}`)
                if (element.is(':visible')){
                    element.slideUp()
                }else{
                    element.slideDown()
                }
            },
            selectGame(gameId,type = 'game') {
                this.$store.dispatch("games/getGameTime").then((response)=>{
                    let serverTime = response.data
                    let VueBox = this;
                    let game = this.games.find((g)=> g.id ===gameId)
                    this.hour = game.time
                    let date = serverTime+" "+this.hour
                   // let localDate = moment.tz(date, "America/New_York");
                    let localDate = moment.tz(date, "America/New_York");
                    $('#clock').countdown(localDate.toDate(), function(event) {
                        $(this).html(event.strftime('%H:%M:%S'));
                    }).on('update.countdown',function (event) {
                    }).on('finish.countdown',function (event) {
                        if (type === "game"){
                            VueBox.showTimePassedDialog()
                            this.item.game_id = ""
                            Object.assign(this.$data, this.$options.data())
                        }
                    });
                    let time = $('#clock').text()
                    switch (type){
                        case "game" :
                            /*if (!time.includes("00:00:00")){
                                this.showTimePassedDialog()
                                this.blackListedGames.push(gameId)
                            }else{*/
                                this.item.game_id = gameId
                                this.configs = this.gameConfigs.find((config)=>config.game_id === gameId)
                            //}
                            break;
                        case "tirage" :
                            /*if (!time.includes("00:00:00")){
                                this.$swal('Erreur',"L'heure de fermeture de ce jeu n'est pas encore atteint","error")
                            }else{*/
                            this.item.game_id = gameId
                          //  }
                            break
                    }
                })
            },
            updateCountDown(id){
                this.$store.dispatch("games/getGameTime").then((response)=>{

                    let serverTime = response.data

                    let VueBox = this;
                    let game = this.games.find((g)=> g.id ===id)
                    this.hour = game.time
                    let today = new Date();
                    let date = today.getFullYear()+"-"+(1+today.getMonth())+"-0"+(today.getDate())+" "+this.hour
                    //var localDate = moment.tz(date, "America/New_York");
                    var localDate = moment(date).add(6,"hours")

                    $('#clock').countdown(localDate.toDate(), function(event) {
                        $(this).html(event.strftime('%H:%M:%S'));
                    }).on('update.countdown',function (event) {
                    }).on('finish.countdown',function (event) {
                        VueBox.showTimePassedDialog()
                    });

                    let time = $('#clock').text()
                    if (time.includes("00:00:00")){
                        this.showTimePassedDialog()
                        this.blackListedGames.push(id)

                        return false;
                    }

                    return true;
                })
            },
            showTimePassedDialog(){
                this.$swal({
                    title: 'Temps de jeu', text: `Oups! L'heure pour jouer ce jeu est déjà passée.`,
                    icon: 'warning',
                    showCancelButton: false,
                    allowOutsideClick:false,
                    confirmButtonText: "D'accord"
                })
                    .then((value => {
                        //this.$router.go(-1)

                    }))
            },
            ballIsSelected(ball, balls) {
                return balls.includes(ball)
            },
            formatCombination(balls) {
                return [balls.toString().substr(0, 2), balls.toString().substr(2, 2),]
            },
            fileChange() {
                this.item.file = this.$refs.logo.$refs.input.files[0];
                if (this.item.file)
                    this.previewImage = URL.createObjectURL(this.item.file);
            },
            clearImage() {
                this.item.file = null;
                this.previewImage = "";
            },
            getRoleName(name) {
                let full = ""
                switch (name) {
                    case "deliverer":
                        full = "Achéteuse"
                        break;
                    case "co":
                        full = "Chef d'opération"
                        break;
                    case "admin":
                        full = "Administrateur"
                        break;
                }
                return full;
            },
            showImages() {
                const viewer = this.$el.querySelector('.images').$viewer
                viewer.show()
            },
            phoneChange(e) {
                this.phoneIsValid = e.valid
                if (e.valid) {
                    this.item.telephone = e.number;
                }
            },
            phoneEnterpriseChange(e) {
                this.phoneEnterpriseIsValid = e.valid
                if (e.valid) {
                    this.item.enterprise.telephone = e.number;
                }
            },
            getTimeElapsed(date) {
                let now = moment(new Date());
                let end = moment(date);
                let duration = moment.duration(now.diff(end));

                return duration.asMinutes();
            },
            formatFilePath(path) {
                return JSON.parse(path)
            },
            close(dialog = 'dialog') {
                this[dialog] = false;
                this.update = false;
                this.submitting = false;
                Object.assign(this.$data, this.$options.data())
                this.$refs.form.reset()

            },
            getStatusColor(status) {
                let response = {};
                switch (status) {
                    case "init":
                        response.color = 'badge-warning';
                        response.status = 'En attente';
                        break;

                    case "accepted":
                        response.color = 'badge-success';
                        response.status = 'Validée';
                        break;
                    case "refused":
                        response.color = 'badge-danger';
                        response.status = 'Refusée';
                        break;
                    default:
                        response.color = 'badge-default';
                        response.status = '--';
                        break;
                }
                return response;
            },
            formatAmount(amount) {
                return new Intl.NumberFormat().format(amount);
            },
            formatDate(date) {
                return new Intl.DateTimeFormat('fr-Fr', {
                    dateStyle: 'short',
                    timeStyle: 'short'
                }).format(new Date(date));
            },
            formatFullDate(date) {
                return new Intl.DateTimeFormat('fr-Fr', {dateStyle: 'full', timeStyle: 'short'}).format(new Date(date));
            },
        }
    }
}
