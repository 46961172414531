<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <h4 class="text-start fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Tableau de bord/</span> Produit
    </h4>
    <div v-if="products">
      <v-card>
        <div class="d-flex justify-content-end">
          <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="dialog = true">
            <v-icon left
                    dark>
              mdi-plus
            </v-icon>
            Ajouter
          </v-btn>
        </div>
        <div class="d-flex justify-content-end">
          <v-col md="4">
            <v-text-field solo
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Rechercher"
                          single-line
                          hide-details
            ></v-text-field>
          </v-col>
        </div>
        <v-data-table
            :headers="headers"
            :items="products"
            loading-text="Chargement des données"
            :search="search">
          <template v-slot:item.is_active="{item}">
            <v-switch   @click="changeStatus(item)" color="primary"
                       :input-value="item.is_active">
            </v-switch>
          </template>

          <template v-slot:item.created_at="{item}">
            {{ formatDate(item.created_at)}}
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-menu
                  key="text"
                  rounded="b-xl">
                <template v-slot:activator="{ attrs, on }">

                  <v-icon
                      class="black--text "
                      v-bind="attrs"
                      v-on="on">mdi-dots-vertical
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(action_item, index) in actions_items"
                      :key="index"
                      link
                      @click="editItemAction(item,index,'products')">
                    <v-list-item-icon>
                      <v-icon color="primary">{{action_item.avatar}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="action_item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template v-slot:no-data>
            <span>Aucune donnée a affichée</span>
          </template>
        </v-data-table>
      </v-card>

      <!-- Dialog start -->
      <v-dialog v-if="addProduct" v-model="dialog"
                persistent
                fullscreen>

        <v-card>
          <v-btn class="float-end m-1"  color="transparent" @click="close()">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
          <v-card-title>
            <span class="text-h5">Nouveau produit</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="formIsValid">
              <validation-errors v-if="validationErrors" :error="validationErrors"/>
              <br>
              <v-progress-linear
                  v-if="submitting"
                  indeterminate
                  color="primary"
              ></v-progress-linear>

              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <label>Nom du produit</label>
                  <v-text-field :rules="[rules.name]" solo
                                label="Nom du produit"
                                v-model="item.name"
                                required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6">
                  <label>Description</label>
                  <v-text-field solo  :rules="[rules.description]"
                                label="Description"
                                v-model="item.description"
                                required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="text-center">
                <v-btn v-for="borlette in createData?.numbers"
                       :key="borlette.id" @click="selectBall(borlette.id)"
                       class="ma-2"
                       small
                       :outlined="!item.borlettes.includes(borlette.id)"
                       fab
                       color="primary">
                  {{borlette.number}}
                </v-btn>
              </v-row>

            </v-form>
          </v-card-text>
          <v-card-actions style="padding-bottom: 10px">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                :loading="submitting"
                :disabled="!formIsValid || submitting"
                @click="save('products')">
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="detailsDialog"
                persistent
                fullscreen>

        <v-card v-if="product">
          <v-btn class="float-end m-1"  color="transparent" @click="close()">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
          <v-card-title>
            <span class="text-h5">{{product?.name}}</span>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-2 mb-4">
              <h5>Borlettes</h5>
              <v-btn v-for="borlette in product.borlettes"
                     :key="borlette.id"
                     class="ma-2"
                     x-small
                     fab
                     color="primary">
                {{borlette.number}}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Dialog end -->

    </div>
    <v-overlay v-else>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
// @ is an alias to /src

import ValidationErrors from "@/components/ValidationErrors";
import crudMixin from "@/mixins/crudMixin";
import globalMixins from "@/mixins/globalMixins";
import {mapState} from "vuex";
export default {
  name: 'Products',
  components: {ValidationErrors},
  mixins:[crudMixin(),globalMixins()],
  data() {
    return {
      headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {text: "Description", value: 'description'},
        { text: 'Statut', value: 'is_active' },
        { text: 'Date', value: 'created_at' },
        {text: 'Actions', value: 'actions'},
      ],
      search: "",
      dialog: false,
      addProduct: true,
      formIsValid: false,
      submitting: false,
      item: {
        borlettes : []
      },
      validationErrors: undefined,
      update: false,
      detailsDialog: false,
      product : undefined,
      rules:{
        name: v => !!v ||  "Le nom est requis",
        description: v => !!v ||  "Le nom est requis",
        borlettes: v => !!v ||  "Le type est requis",
      },
      actions_items: [
        { title: "Détails", avatar: 'mdi-eye',permission:'read-product' },
        { title: "Modifier", avatar: 'mdi-pencil',permission:'update-product' },
        { title: "Supprimer", avatar: 'mdi-delete',permission:'delete-product' },
      ],

    }
  },
  mounted() {
    this.init('products')
    this.create('products')
  },
  computed:{
    ...mapState('products',['products','createData']),
  },
  methods:{
    edit(item) {
      this.item = item
      this.dialog = true;
      this.update = true;
      this.item.borlettes = item.borlettes.map((bor) => bor.id)
    },
    selectBall(borletteId){
      if (this.item.borlettes.includes(borletteId)){
        this.item.borlettes = this.item.borlettes.filter((b) => b !== borletteId)
      }else{
        this.item.borlettes.push(borletteId)
      }
    },
    editItemAction(item, index, module) {
      switch (index) {
        case 0:
          this.product = item;
          this.detailsDialog = true;
          break;
          case 1:
          this.edit(item);
          break;
        case 2:
          this.addProduct = false;
          this.dialog = true;
          this.item.product_id = item.id

          this.item.marriages =''
          this.item.lotto4s =''
            item.marriages.forEach((marriage,index) => {
               this.item.marriages +=`${marriage.borlette1.number}*${marriage.borlette2.number}${(index+1) < item.marriages.length?';':''}`
            })
          item.lotto4s.forEach((lotto4,index) => {
               this.item.lotto4s +=`${lotto4.borlette1.number}x${lotto4.borlette2.number}${(index+1) < item.lotto4s.length?';':''}`
            })
          break;
        case 3:
          this.destroy(item, module)
          break;

      }
    },
    changeStatus(item){
      this.$store.dispatch("products/changeStatus",item.id)
          .then((response)=>{
        this.toast('success','Opération effectuée avec succès.')
      }).catch((error) => {
        this.toast('error','Opération effectuée avec succès.')
      })

    }
  }
}
</script>
