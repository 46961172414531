import Vue from 'vue'
import Vuex from 'vuex'
import {auth} from "@/store/modules/auth";
import {types} from "@/store/modules/types";
import {categories} from "@/store/modules/categories";
import {games} from "@/store/modules/games";
import {clients} from "@/store/modules/clients";
import {playeds} from "@/store/modules/playeds";
import {staff} from "@/store/modules/staff";
import {balls} from "@/store/modules/balls";
import {tirages} from "@/store/modules/tirages";
import {gameConfigs} from "@/store/modules/gameConfigs";
import {gains} from "@/store/modules/gains";
import {enterprises} from "@/store/modules/enterprises";
import {dashboard} from "@/store/modules/dashboard";
import {licences} from "@/store/modules/licences";
import {products} from "@/store/modules/products";
import {productsMarriages} from "@/store/modules/productsMarriages";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth:auth,
    types:types,
    categories:categories,
    games:games,
    clients:clients,
    playeds:playeds,
    staff:staff,
    balls:balls,
    tirages:tirages,
    gameConfigs:gameConfigs,
    gains:gains,
    enterprises:enterprises,
    dashboard:dashboard,
    licences:licences,
    products:products,
    productsMarriages:productsMarriages
  }
})
