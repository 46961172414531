import auth from "@/router/middlewares/auth";
import Products from "@/views/Products/Products.vue";

const routes = [
    {
        path:'/products',
        name:'products.index',
        component:Products,
        meta: {
            middleware: [
                auth,
                //permission
            ],
            permission: 'browse-product'
        }
    },

];

export  default routes;
