import {edit, get, create, store, update, destroy, updateFormData} from "@/services/resources";
import {GAME_CONFIGS, GAME_CONFIGS_BLACKLIST} from "@/services/apis";

export  const gameConfigs = {
    namespaced:true,
    state:{
        gameConfigs:undefined,
    },
    mutations: {
        SET_GAME_CONFIGS(state,payload){
            state.gameConfigs = payload
        },



    },
    actions: {
        get({commit},module){
            return new Promise((resolve,reject)=>{
                get(GAME_CONFIGS).then((response)=>{
                    commit('SET_GAME_CONFIGS',response.data)
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        store({commit},data){
            return new Promise((resolve,reject)=>{
                store(GAME_CONFIGS,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
        storeBlacklist(_,data){
            return new Promise((resolve,reject)=>{
                store(GAME_CONFIGS_BLACKLIST,data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },

    },
    getters: {

    }
}
