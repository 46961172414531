export const LOGIN = "login"//"oauth/token";
export const VERIFY = "users/verify/";
export const FIND_TOKEN = "forgot-password/";
export const RESET_PASSWORD = "reset-password";
export const PASSWORD_FORGOT = "forgot-password";
export const REGISTER = "clients/subscribe"//"oauth/token";
export const LOGOUT = "logout";
export const GET_USER_INFOS = "login";
export const CATEGORIES = "categories"
export const TYPES = "types"
export const LICENCES = "licences"
export const GAMES = "games"
export const PRODUCTS = "products"
export const CLIENTS = "clients"
export const ENTERPRISES = "enterprises"
export const PLAYEDS = "playeds"
export const STAFF = "staff"
export const UPDATE_PROFILE = "update-profile"
export const TIRAGES = "tirages"
export const GAME_CONFIGS = "game-configs"
export const GAME_CONFIGS_BLACKLIST = "game-configs/blacklist"
export const GAINS = "gains"
