<template>
  <div class="container-fluid flex-grow-1 container-p-y">
    <div>

      <div class="d-flex justify-content-start games-container">
        <v-btn rounded color="primary" v-for="(game) in games" :key="game.id" :outlined="item.game_id!==game.id"
               class="m-2" @click="selectGame(game.id)">{{ game.name + ' ' + game.type.name }}
        </v-btn>
      </div>
      <div>
        <v-tabs v-model="tab" grow>
          <v-tab>Boules</v-tab>
          <v-tab>Lotto 3</v-tab>
          <v-tab>Lotto 4</v-tab>
          <v-tab>Marriage</v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="card bg-white p-4">
                <div class="row">
                  <h4 class="col-md-10 d-flex align-self-center">
                    Contrôler les boule à rétirer du jeux
                  </h4>
                  <div class="col-md-1">
                    <v-switch
                        v-model="item.control_balls"
                        inset
                        label=""
                    ></v-switch>
                  </div>
                  <div v-if="item.control_balls">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="col-2">
                        <input :class="[hasError(`borlette`)?'is-invalid':'']" maxlength="2" type="tel"
                               v-model="item.borlette" class="form-control" placeholder="borlette"/>
                        <span v-if="hasError(`borlette`)" class="invalid-feedback" role="alert">
                                            <strong>{{ validationErrors[`borlette`][0] }}</strong>
                                          </span>
                      </div>
                      <div class="col-1">
                        <button :disabled="!item.borlette || item?.borlette.length !== 2"
                                @click="blacklistElement('borlette',item.id)">
                          <v-icon color="error">mdi-check</v-icon>
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <v-btn v-for="(ball,index) in item.balls"
                             :key="'ball-'+index"
                             class="ma-2"
                             fab
                             small
                             @click="blacklistElement('borlette',item.id,ball)"
                             color="primary">
                        {{ ball }}
                      </v-btn>
                    </div>
                    <div class="row">
                      <h6 class="col-md-8 d-flex align-self-center">
                        Gain Maximal Autorisé sur une ball
                      </h6>
                      <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                        <v-text-field v-model="item.balls_max_mise" type="number" solo/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="card bg-white p-4">
                <div class="row">
                  <h4 class="col-md-10 d-flex align-self-center">
                    Contrôler les combinaison lotto 3
                  </h4>
                  <div class="col-md-1">
                    <v-switch
                        v-model="item.control_lotto3"
                        inset
                        label=""
                    ></v-switch>
                  </div>
                  <div v-if="item.control_lotto3">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="col-2">
                        <input :class="[hasError(`lotto3`)?'is-invalid':'']" maxlength="3" type="tel"
                               v-model="item.lotto3" class="form-control" placeholder="Lotto3"/>
                        <span v-if="hasError(`lotto3`)" class="invalid-feedback" role="alert">
                          <strong>{{ validationErrors[`lotto3`][0] }}</strong>
                        </span>
                      </div>
                      <div class="col-1">
                        <button :disabled="!item.lotto3 || item?.lotto3.length !== 3"
                                @click="blacklistElement('lotto3',item.id)">
                          <v-icon color="error">mdi-check</v-icon>
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <v-btn v-for="(ball,index) in item.lotto3_balls"
                             :key="'lotto3-'+index"
                             class="ma-2"
                             fab
                             small
                             @click="blacklistElement('lotto3',item.id,ball)"
                             color="primary">
                        {{ ball }}
                      </v-btn>
                    </div>
                    <div class="row">
                      <h6 class="col-md-8 d-flex align-self-center">
                        Gain Maximal Autorisé pour un lotto 3
                      </h6>
                      <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                        <v-text-field v-model="item.lotto3_max_mise" type="number" solo/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="card bg-white p-4">
                <div class="row">
                  <h4 class="col-md-10 d-flex align-self-center">
                    Contrôler les combinaisons lotto 4
                  </h4>
                  <div class="col-md-1">
                    <v-switch
                        v-model="item.control_lotto4"
                        inset
                        label=""
                    ></v-switch>
                  </div>
                  <div v-if="item.control_lotto4">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="col-2">
                        <input :class="[hasError(`lotto4`)?'is-invalid':'']" maxlength="4" type="tel"
                               v-model="item.lotto4" class="form-control" placeholder="lotto4"/>
                        <span v-if="hasError(`lotto4`)" class="invalid-feedback" role="alert">
                          <strong>{{ validationErrors[`lotto4`][0] }}</strong>
                        </span>
                      </div>
                      <div class="col-1">
                        <button :disabled="!item.lotto4 || item?.lotto4.length !== 4"
                                @click="blacklistElement('lotto4',item.id)">
                          <v-icon color="error">mdi-check</v-icon>
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <v-btn v-for="(ball,index) in item.lotto4_balls"
                             :key="'lotto4-'+index"
                             class="ma-2"
                             fab
                             @click="blacklistElement('lotto4',item.id,ball)"
                             color="primary">
                        {{ ball }}
                      </v-btn>
                    </div>
                    <div class="row">
                      <h6 class="col-md-8 d-flex align-self-center">
                        Gain Maximal Autorisé pour un lotto4
                      </h6>
                      <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                        <v-text-field v-model="item.lotto4_max_mise" type="number" solo/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="card bg-white p-4">
                <div class="row">
                  <h4 class="col-md-10 d-flex align-self-center">
                    Contrôler les marriages
                  </h4>
                  <div class="col-md-1">
                    <v-switch
                        v-model="item.control_marriage"
                        inset
                        label=""
                    ></v-switch>
                  </div>
                  <div v-if="item.control_marriage">
                    <div class="d-flex justify-content-center align-items-center">
                      <div class="col-2">
                        <input :class="[hasError(`marriage`)?'is-invalid':'']" maxlength="4" type="tel"
                               v-model="item.marriage" class="form-control" placeholder="Marriage"/>
                        <span v-if="hasError(`marriage`)" class="invalid-feedback" role="alert">
                          <strong>{{ validationErrors[`marriage`][0] }}</strong>
                        </span>
                      </div>
                      <div class="col-1">
                        <button :disabled="!item.marriage || item?.marriage.length !== 4"
                                @click="blacklistElement('marriage',item.id)">
                          <v-icon color="error">mdi-check</v-icon>
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <v-btn v-for="(ball,index) in item.marriage_balls"
                             :key="'marriage-'+index"
                             class="ma-2"
                             fab
                             @click="blacklistElement('marriage',item.id,ball)"
                             color="primary">
                        {{ ball }}
                      </v-btn>
                    </div>
                    <div class="row">
                      <h6 class="col-md-8 d-flex align-self-center">
                        Gain Maximal Autorisé pour un marriage
                      </h6>
                      <div class="col-md-4 d-flex justify-content-end align-self-center mt-4">
                        <v-text-field v-model="item.marriage_max_mise" type="number" solo/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <div class="d-flex justify-content-end mt-4">
          <v-btn color="primary" @click="updateConfig" :loading="submitting">Enrégitré</v-btn>
        </div>
      </div>
    </div>
    <v-overlay :value="false">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState} from "vuex";
import globalMixins from "@/mixins/globalMixins";
import crudMixin from "@/mixins/crudMixin";
import {includes} from "core-js/internals/array-includes";

export default {
  name: "Configuration",
  mixins: [globalMixins(this), crudMixin(this)],
  data() {
    return {
      tab: '',
      validationErrors: undefined,
      submitting: false,
      dialog: false,
      update: false,
      item: {
        game_id: '',
        control_balls: false,
        control_marriage: false,
        control_lotto3: false,
        control_lotto4: false
      },
      configs: {}
    }
  },
  async mounted() {
    await this.$store.dispatch('balls/resetBalls')
    await this.$store.dispatch('games/get', "games")
    this.init(this.games[0].id)
  },
  methods: {
    includes,
    init(gameId) {
      this.$store.dispatch('gameConfigs/get').then(({data}) => {
        if (data) {
          let configs = data.find((config) => gameId === config.game_id)
          if (configs) {
            this.item = configs
          } else {
            this.item = {game_id: gameId}
          }
        }
      })
    },
    updateConfig() {
      this.submitting = true;
      this.item.balls = this.selectedBalls
      this.item.lotto3_balls = this.lotto3Balls
      this.item.lotto4_balls = this.lotto4Balls
      this.item.marriage_balls = this.marriageBalls
      this.$store.dispatch(`gameConfigs/store`, this.item).then(() => {
        this.submitting = false;
        this.$swal('Configuration', 'Configurations mises à jour avec succès', 'success')
      }).catch((error) => {
        this.submitting = false;
        switch (error.response.status) {
          case 500 :
            this.$swal('Configuration', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
            break;
          case 422:
            this.validationErrors = error.response.data
            break;
        }
      });

    },
    blacklistElement(element, configId, value = undefined) {
      this.submitting = true;
      this.item.type = element
      if (value) {
        this.item[element] = value
      }
      this.item.game_config_id = configId
      this.$store.dispatch(`gameConfigs/storeBlacklist`, this.item).then(async () => {
        this.submitting = false;
        this.init(this.item.game_id)
        this.$swal('Configuration', 'Configurations mises à jour avec succès', 'success')
        this.item[element] = ''
      }).catch((error) => {
        this.submitting = false;
        switch (error.response.status) {
          case 500 :
            this.$swal('Configuration', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
            break;
          case 422:
            this.validationErrors = error.response.data.error
            break;
        }
      });

    },
    selectGame(gameId) {
      this.item.game_id = gameId;
      this.init(gameId)
    }
  },
  computed: {
    ...mapState('games', ['games']),
    ...mapState('gameConfigs', ['gameConfigs'])
  }

}
</script>