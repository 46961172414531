import Vue from 'vue'
import VueRouter from 'vue-router'
import globalRoutes from "./routes"
import middlewarePipeline from "@/router/middlewares/middlewarePipeline";
import store from '@/store/'
import V2Routes from "@/router/V2Routes";

Vue.use(VueRouter)
const routes = [
    ...globalRoutes,...V2Routes]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })

});

export default router
