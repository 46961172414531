<template>
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="text-start fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Tableau de bord/</span>
          <span v-if="userHasPermission('manage-all')"> Utilisateurs</span>
          <span v-else> Vendeurs</span>
        </h4>
        <div v-if="staff">
            <v-card>
                <div class="d-flex justify-content-end" v-if="!userHasPermission('manage-all')">
                    <v-btn
                            color="primary"
                            class="ma-2 white--text"
                            @click="dialog = true">
                        <v-icon left
                                dark>
                            mdi-plus
                        </v-icon>
                        Ajouter
                    </v-btn>
                </div>
                <div class="d-flex justify-content-end">
                    <v-col md="4">
                        <v-text-field solo
                                      v-model="search"
                                      append-icon="mdi-magnify"
                                      label="Rechercher"
                                      single-line
                                      hide-details
                        ></v-text-field>
                    </v-col>
                </div>
                <v-data-table
                        :headers="headers"
                        :items="staff"
                        loading-text="Chargement des données"
                        :search="search">
                    <template v-slot:item.identity.email="{item}">
                        <a :href="'mailto:'+item.identity.email" style="color: #3AAA35">
                            {{ item.identity.email}}
                        </a>
                    </template>
                    <template v-slot:item.identity.telephone="{item}">
                        <v-btn outlined link :href="`tel:${item.identity.telephone}`"
                               color="primary">
                            <v-icon>mdi-phone</v-icon>
                            {{ item.identity.telephone}}
                        </v-btn>
                    </template>
                    <template v-slot:item.created_at="{item}">
                        {{ formatDate(item.created_at)}}
                    </template>
                  <template v-slot:item.status="{item}">
                    <v-switch  :disabled="userHasPermission('manage-all')" v-if="item.identity.user" @click="changeUserStatus(item.identity.user)" color="primary"
                               :input-value="(item.identity.user.disabled_at==null  )?'true':''">
                    </v-switch>
                  </template>
                    <template v-slot:item.actions="{ item }">
                        <div>
                            <v-menu
                                    key="text"
                                    rounded="b-xl">
                                <template v-slot:activator="{ attrs, on }">

                                    <v-icon
                                            class="black--text "
                                            v-bind="attrs"
                                            v-on="on">mdi-dots-vertical
                                    </v-icon>

                                </template>
                                <v-list>
                                    <v-list-item
                                            v-for="(action_item, index) in actions_items"
                                            :key="index"
                                            link
                                            @click="editItemAction(item,index)">
                                        <v-list-item-icon>
                                            <v-icon color="primary">{{action_item.avatar}}</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="action_item.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <span>Aucune donnée a affichée</span>
                    </template>
                </v-data-table>
            </v-card>

            <!-- Dialog start -->
            <v-dialog v-model="dialog"
                      persistent
                      max-width="600px">

                <v-card>
                    <v-btn class="float-end m-1" color="transparent" @click="close()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                    <v-card-title>
                        <span class="text-h5" v-if="!update">Nouveau vendeur</span>
                        <span class="text-h5" v-else>Mis à jour vendeur</span>
                    </v-card-title>
                    <v-card-text>
                        <validation-errors v-if="validationErrors" :error="validationErrors"/>
                        <v-progress-linear
                                v-if="submitting"
                                indeterminate
                                color="primary"
                        ></v-progress-linear>
                        <br/>
                        <v-form ref="form" v-model="formIsValid">
                            <v-row>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                    <v-text-field solo :rules="[rules.lastname]"
                                                  label="Nom"
                                                  v-model="item.lastname"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                    <v-text-field solo :rules="[rules.firstname]"
                                                  label="Prénom"
                                                  v-model="item.firstname"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                    <v-text-field solo :rules="[rules.email_rule]"
                                                  label="Email"
                                                  type="mail"
                                                  v-model="item.email"
                                                  required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        sm="6"
                                        md="6">
                                  <VueTelInput
                                      class="v-text-field tel-input"
                                      :defaultCountry="229"
                                      invalidMsg="Numéro de téléphone invalid"
                                      @validate="phoneChange"
                                      v-model="item.telephone"
                                      :autoDefaultCountry="false"></VueTelInput>
                                  <small class="text-danger" v-if="item.telephone && item.telephone.length &&  !phoneIsValid">Numéro de téléphone
                                    invalid
                                    {{phoneIsValid}}</small>

                                </v-col>

                              <v-col
                                  cols="12"
                                  sm="12"
                                  md="12">
                                <v-text-field solo :rules="[rules.number,rules.positive,rules.required]"
                                              label="Commission"
                                              type="number"
                                              v-model="item.commission"
                                              required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                :loading="submitting"
                                :disabled="!phoneIsValid || !formIsValid || submitting"
                                @click="save('staff')">
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
            <!-- Dialog end -->

        </div>
        <v-overlay v-else>
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ValidationErrors from "@/components/ValidationErrors";
    import crudMixin from "@/mixins/crudMixin";
    import globalMixins from "@/mixins/globalMixins";
    import {mapState} from "vuex";
    import {VueTelInput} from 'vue-tel-input'

    export default {
        name: 'Users',
        mixins:[crudMixin(),globalMixins()],
        components: {ValidationErrors,VueTelInput},
        data() {
            return {
                headers: [
                    {text: 'Nom', value: 'identity.lastname',},
                    {text: 'Prénom', value: 'identity.firstname',},
                    {text: 'Email', value: 'identity.email'},
                    {text: 'Commission (%)', value: 'identity.user.commission'},
                    {text: 'Téléphone', value: 'identity.telephone'},
                    {text: 'Statut', value: 'status'},
                    //{text: 'Entreprise', value: 'startup'},
                    {text: 'Actions', value: 'actions'},
                ],
                search: "",
                dialog: false,
                formIsValid: false,
                submitting: false,
              phoneIsValid: false,
                item: {},
                validationErrors: undefined,
                update: false,
                rules:{
                  firstname: v => !!v ||  "Le prénom est requis",
                  lastname: v => !!v ||  "Le nom  est requis",
                  sexe: v => !!v ||  "Le sexe est requis",
                  rule: v => !!v ||  "Le rôle de l'utilisateur est requis",
                  tel: v => !!v ||  "Le numéro de téléphone est requis",
                  number: v => !isNaN(v) || "Valeur invalid",
                  positive: v => !isNaN(v) && v > 0 || "La valeur de ce champ doit être supérieur à zéro",
                  required: v => !!v || "Ce champ est requis",
                  addresse: v => !!v ||  "L'adresse de l'entreprise est requise",
                  email_required: v => !!v ||  "l'émail est requis",
                  email_rule: v =>  !!v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ||  "Email invalide",
                },
                actions_items: [
                    { title: "Détails", avatar: 'mdi-eye',permission:'' },
                    { title: "Modifier", avatar: 'mdi-pencil',permission:'' },
                    //{ title: "Supprimer", avatar: 'mdi-delete',permission:'' },
                ],

            }
        },
        computed :{
            ...mapState('staff',['staff'])
        },
        mounted() {
            this.init('staff')
        },
        methods:{
            edit(item){
                this.item = item.identity
                this.item.id = item.id
                this.item.identity_id = item.identity.user.identity_id
                this.dialog = true;
                this.update = true;
            },
          editItemAction(item, index) {
            switch (index) {
              case 0:
                this.$router.push(`sellers/${item.id}`);
                break;
              case 1:
                this.edit(item);
                break;


            }
          }
        }

    }
</script>
