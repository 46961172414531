<template>
    <div class="container-fluid flex-grow-1 container-p-y">
        <h4 class="text-start fw-bold py-3 mb-4">
          <span class="text-muted fw-light">Tableau de bord/</span>
          <span> Détails</span>
        </h4>
        <div v-if="staffDetails">
          <div class="bg-white p-4">
            <div class="row">
              <h4>
                {{staffDetails.staff.identity?.firstname + ' ' +staffDetails.staff.identity?.lastname}}
              </h4>
              <div class="d-flex justify-end text-primary">username : {{staffDetails.staff.identity.user.username}}</div>
            </div>
            <div class="row">
              <div class="col-3">
                <h6>Nombre de ticket vendu</h6>
                <h1>{{staffDetails.stats.total_sells}}</h1>
              </div>
              <div class="col-3">
                <h6>Total chiffre d'affaire</h6>
                <h1>{{staffDetails.stats.total_sells_amount}}</h1>
              </div>
              <div class="col-3">
                <h6>Total paiement reçu</h6>
                <h1>{{staffDetails.stats.total_commission}}</h1>
              </div>
              <div class="col-3">
                <h6>Profit/Perte</h6>
                <h1>{{staffDetails.stats.total_gains}}</h1>
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <v-card>
              <div class="d-flex justify-content-end" v-if="!userHasPermission('manage-all')">
                <v-btn
                    color="primary"
                    class="ma-2 white--text"
                    @click="credentialsDialog = true">
                  <v-icon left
                          dark>
                    mdi-lock
                  </v-icon>
                  Credentials
                </v-btn>
                <v-btn
                    color="primary"
                    class="ma-2 white--text"
                    @click="dialog = true">
                  <v-icon left
                          dark>
                    mdi-plus
                  </v-icon>
                  Assigner des produits
                </v-btn>
              </div>
              <div class="d-flex justify-content-end">
                <v-col md="4">
                  <v-text-field solo
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Rechercher"
                                single-line
                                hide-details
                  ></v-text-field>
                </v-col>
              </div>
              <v-data-table
                  :headers="headers"
                  :items="staffDetails.staff.products"
                  loading-text="Chargement des données"
                  :search="search">
                <template v-slot:no-data>
                  <span>Aucune donnée a affichée</span>
                </template>
              </v-data-table>
            </v-card>
          </div>
            <!-- Dialog start -->
            <v-dialog v-model="credentialsDialog"
                      persistent
                      max-width="600px">

                <v-card>
                    <v-btn class="float-end m-1" color="transparent" @click="close()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                    <v-card-title>
                        <span class="text-h5">Mettre à jour les credentials</span>
                    </v-card-title>
                    <v-card-text>
                        <validation-errors v-if="validationErrors" :error="validationErrors"/>
                        <v-progress-linear
                                v-if="submitting"
                                indeterminate
                                color="primary"
                        ></v-progress-linear>
                        <br/>
                        <v-form ref="form">
                          <v-row>
<!--                            <v-text-field
                                solo
                                :rules="[rules.required]"
                                v-model="item.username"
                                label="Username"
                            />-->
                              <v-text-field
                                solo
                                :rules="[rules.required]"
                                v-model="item.username"
                                label="Username"
                                append-icon="mdi-refresh"
                                @click:append="generateCode()"
                            />
                          </v-row>
                          <v-row>
                            <v-text-field
                                v-model="item.password"
                                label="Mot de passe"
                                :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                                @click:append="showPass=!showPass"
                                :type="showPass ? 'text' : 'password'"
                                solo
                            />
                          </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                :loading="submitting"
                                @click="updateCredentials()">
                            Mettre à jour
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
            <v-dialog v-model="dialog"
                      persistent
                      max-width="600px">

                <v-card>
                    <v-btn class="float-end m-1" color="transparent" @click="close()">
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                    <v-card-title>
                        <span class="text-h5">Assignation de produit</span>
                    </v-card-title>
                    <v-card-text>
                        <validation-errors v-if="validationErrors" :error="validationErrors"/>
                        <v-progress-linear
                                v-if="submitting"
                                indeterminate
                                color="primary"
                        ></v-progress-linear>
                        <br/>
                        <v-form ref="form" v-model="formIsValid">
                            <v-row>
                              <v-col md="4" v-for="product in staffDetails?.products" :key="product.id">
                                <v-checkbox
                                    v-model="item.products"
                                    :label="product.name"
                                    :value="product.id"
                                    />
                              </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                :loading="submitting"
                                @click="save()">
                            Enregistrer
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
            <!-- Dialog end -->

        </div>
        <v-overlay v-else>
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ValidationErrors from "@/components/ValidationErrors";
    import crudMixin from "@/mixins/crudMixin";
    import globalMixins from "@/mixins/globalMixins";
    import {mapState} from "vuex";
    export default {
        name: 'SellerDetails',
        mixins:[crudMixin(),globalMixins()],
        components: {ValidationErrors},
        data() {
            return {
              showPass:false,
              username: "",
              password: "",
              headers: [
                {text: 'Nom', value: 'name',},
                {text: 'Description', value: 'description',},
                {text: 'Actions', value: 'actions'},
              ],
              search: "",
              dialog: false,
              credentialsDialog: false,
                item: {
                  products : []
                },
              validationErrors: undefined,
              update: false,
              submitting: false,
              rules: {
                required: v => !!v ||  "Ce champ est requis",
                min: v => !!v && v.length >= 6 || "Le mot de passe doit contenir au moins 6 caractères",
              },
            }
        },
        computed :{
            ...mapState('staff',['staffDetails'])
        },
        mounted() {
            this.init()
        },
        methods:{
          init(){
            this.$store.dispatch('staff/show',this.$route.params.id).then(({data}) => {
              this.item.staff_id = data.staff.id
              this.item.username = data.staff.identity.user.username
              this.item.password = ''
              this.item.products = data.staff.products.map((p) => p.id)
            })
          },
          save(){
            this.$store.dispatch(`staff/assignProducts`, this.item).then(() => {
              this.submitting = false;
              this.init();
              this.$swal('Assignation', 'Assignation mise à jour avec succès', 'success')
              this.close();
            }).catch((error) => {
              this.submitting = false;
              if (error.response){
                switch (error.response.status) {
                  case 500 :
                    this.$swal('Assignation', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                    break;
                  case 422:
                    this.toast('error','Des erreur de validation son survenues')
                    this.validationErrors = error.response.data
                    break;
                }
              }
            });
          },
          updateCredentials(){
            delete this.item.products
            if (this.item.password.length === 0 || !this.item.password){
              delete this.item.password
            }

            this.$store.dispatch(`staff/updateCredentials`, this.item).then(() => {
              this.submitting = false;
              this.$swal('Credentials', 'Credentials mis à jour avec succès', 'success')
              this.close('credentialsDialog');
              this.init()
            }).catch((error) => {
              this.submitting = false;
              if (error.response){
                switch (error.response.status) {
                  case 500 :
                    this.$swal('Credentials', 'Oups! une erreur est survenue, veuillez réssayer', 'error')
                    break;
                  case 422:
                    this.toast('error','Des erreur de validation son survenues')
                    this.validationErrors = error.response.data
                    break;
                }
              }
            });
          },
          generateCode(len = 6) {
            let charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let randomString = '';
            for (let i = 0; i < len; i++) {
              let randomPoz = Math.floor(Math.random() * charSet.length);
              randomString += charSet.substring(randomPoz,randomPoz+1);
            }
            this.item.username = randomString.toUpperCase();
            console.log(this.item)
          },
        }

    }
</script>
